body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.button-default {
  display: inline-block;
  outline: none;
  cursor: pointer;
  padding: 0 12px;
  background-color: #0070d2;
  border-radius: 0.25rem;
  border: 1px solid #0070d2;
  color: #fff;
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
  text-align: center;
  :hover {
    background-color: #005fb2;
    border-color: #005fb2;
  }
}
